import template from './payment_approval_structure_help.html';

class PaymentApprovalStructureHelpViewModel
{
	constructor (dialog)
	{
		this.dialog = dialog;
	};

	close()
	{
		this.dialog.close();
	}
};

class PaymentApprovalStructureHelpPage
{
	constructor (bindings)
	{
		this.viewModel = new PaymentApprovalStructureHelpViewModel(this);
		this.bindings = bindings;
	};
};

// Register the dialog
export default {
	name: 'PaymentApprovalStructureHelp',
	dialog_class: PaymentApprovalStructureHelpPage,
	template: template,
	template_filename: 'translation/dialogs/payment_approval_structure_help',
	provider: 'ps'
};
