import template from './requested_by_search_component.html';

class RequestedBySearchViewModel
{
	constructor (params)
	{
		this.params = params;

		this.payment_users = ko_helper.safe_observableArray();
		this.selected_user = ko_helper.safe_observable(params.selected_user || null);
		this.search_string = ko.observable('');
		this.disable_field = ko_helper.safe_observable(params.disable_field || false);
		this.display_text = ko.observable(this.selected_user() || null);
		this.show_list = ko.observable(false);

		this.selected_user.subscribe((val) => {
			if (val !== null)
				this.display_text(val.fullnames);

			if (this.selected_user() == null)
				this.display_text(null);

			this.payment_users([]);
		});

		this.search_string.subscribe((value) => {
			this.updateData();
			this.show_list(true);
		});

		document.addEventListener('click', this.handle_outside_click.bind(this));
	}

	async init()
	{
		await this.updateData();
	}

	async updateData()
	{
		let options = {
			table: 'v_payment_users',
			schema: 'grape_payment_approval',
			limit: 1000000,
			sortorder: 'ASC',
			sortfield: 'fullnames',
			filter: [{ value: true, op: '=', field: 'active' }]
		};

		if (this.search_string() && this.search_string() != '')
		{
			options.filter.push({
				field: 'fullnames',
				operand: 'ILIKE',
				value: `%${this.search_string()}%`,
			});

			let result = await Grape.fetches.getJSON('/api/record', options);
			if (result.status != 'ERROR')
				if (result.records != null)
					this.payment_users(result.records);
		}
		else
			this.payment_users([]);
	}

	employee_click (v, e)
	{
		this.selected_user(v);
		this.show_list(false);
	}

	handle_outside_click(event)
	{
		// Check if the click is outside the component
		if (!event.target.closest('.requested-by-search-component'))
			this.show_list(false);
	}

	dispose()
	{
		// Remove the click event listener when the component is disposed
		document.removeEventListener('click', this.handle_outside_click);
	}
}

export default {
	name: 'requested_by_search_component',
	viewModel: RequestedBySearchViewModel,
	module_type: 'ko',
	template: template,
	template_filename: 'translation/components/requested_by_search_component'
};