
import template from './user_signature_upload.html';

class UserSignatureUploadViewModel
{
	constructor (dialog)
	{
		this.dialog = dialog;
		this.filename = ko.observable();
		this.user_id = ko.observable(this.dialog.bindings.user_id)
	};

	close()
	{
		this.dialog.close();
	};

	upload_attachment()
	{
		let formData = new FormData($('#batchuploadform')[0]);

		let filename = this.filename();
		let user_id = this.user_id();
		formData.append('user_id', user_id);
		formData.append('filename', filename);
		formData.append('name', filename);
		let xhr = new XMLHttpRequest();

		xhr.open('post', '/payment_approval/user_signature_upload', true);
		xhr.setRequestHeader('Accept', 'application/json');
		xhr.send(formData);

		if (this.filename())
		{
			xhr.onreadystatechange = async () => {
				if (xhr.readyState === 4)
				{
					let res = await JSON.parse(xhr.response);

					if (res.status == 'ERROR')
						Grape.alerts.alert({ type: 'error', message: window.tr('Error'), title: window.tr('Error') });
					else if (res.status == 'OK')
					{
						await Grape.alerts.alert({ type: 'success', message: window.tr('File uploaded successfully.') });
						$('#upload_input').val('');
						this.dialog.close();
					}
					else
						Grape.alerts.alert({type: 'error', message: window.tr('Something went wrong...'), title: window.tr('Error')});
				};
			};
		}
		else
			Grape.alerts.alert({type: 'error', message: window.tr('Please select file to upload'), title: window.tr('Error')});
	};
};

class UserSignatureUploadPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new UserSignatureUploadViewModel(this);

		$('input[id=batch_file]').change(event => {
			let fileName = event.target.value.replace('C:\\fakepath\\', '');
			$('#upload_input').val(fileName);
			this.viewModel.filename(fileName);
		});
	};

	init()
	{
		if (this.bindings.data != 'undefined' && this.bindings.data != null)
		{
			if (this.bindings.user_id != 'undefined' || this.bindings.user_id != null)
				this.viewModel.user_id(this.bindings.user_id);
		};
	};
};

export default {
	name: 'UserSignatureUpload',
	dialog_class: UserSignatureUploadPage,
	template: template,
	template_filename: 'translation/dialogs/user_signature_upload',
	provider: 'ps'
};
