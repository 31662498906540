import template from './payment_search.html';

class paymentSearchViewModel 
{
	constructor(page)
	{
		this.page = page;
		this.records = ko.observableArray();
		this.pending_payments = ko.observableArray();
		this.set_paid_payments = ko.observableArray();
		this.approval_person = ko.observable();
		
		let today = new Date();
		let firstDay = today.setDate(1);
		let lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 1);

		this.date_start = ko.observable(new Date(firstDay).toJSON().slice(0,10));
		this.date_end = ko.observable(new Date(lastDay).toJSON().slice(0,10));

		this.payment_date_start = ko.observable(new Date(firstDay).toJSON().slice(0,10));
		this.payment_date_end = ko.observable(new Date(lastDay).toJSON().slice(0, 10));
		
		this.date_start.subscribe((value) => {
			this.page.updateData();
		});
		this.date_end.subscribe((value) => {
			this.page.updateData();
		});
		
		this.payment_date_start.subscribe((value) => {
			this.page.updateData();
		});
		
		this.payment_date_end.subscribe((value) => {
			this.page.updateData();
		});
	}

	async delete_payment(data)
	{
		let response = await Grape.alerts.confirm({type: 'danger', title: window.tr('Confirm'), message: window.tr('Are you sure you want to delete this payment?')});
		if (response)
		{
			let res = await Grape.fetches.postJSON('/payment/delete', {'payment_id' : data.payment_id });

			if (res.status == 'OK')
			{
				Grape.alerts.alert({type: 'success', message: window.tr('Successfully deleted payment.'), title: window.tr('Success')});
				this.page.updateData();
			}
			else if (res.status == 'ERROR')
				Grape.alerts.apiError(res);
			else
				Grape.alerts.alert({type: 'error', message: window.tr('Something went wrong...'), title: window.tr('Error')});
		}
	};
	
	async approve_payment(data)
	{
		let response = await Grape.alerts.confirm({type: 'info', title: window.tr('Confirm'), message: window.tr('Are you sure you want to approve this payment?')});
		if (response)
		{
			let res = await Grape.fetches.postJSON('/payment/approve', {'payment_id' : data });

			if (res.status == 'OK')
			{
				Grape.alerts.alert({type: 'success', message: window.tr('Successfully approved payment.'), title: window.tr('Success')});
				this.page.updateData();
			}
			else if (res.status == 'ERROR') 
				Grape.alerts.apiError(res);
			else
				Grape.alerts.alert({type: 'error', message: window.tr('Something went wrong...'), title: window.tr('Error')});
		}
	};

	async view_payment(row)
	{
		let data = ko.toJS(row);
		data.view = true;
		await Grape.dialog.open('GrapeAddPayment', { data: data });
		this.page.updateData();
	};

	
	async reject_payment(data)
	{
		let response = await Grape.alerts.confirm({type: 'warning', title: window.tr('Confirm'), message: window.tr('Are you sure you want to reject this payment?')});
		if (response)
		{
			let reason = await Grape.alerts.prompt({type: 'warning', title: tr('Rejecting payment'), message: tr('Please enter the rejection reason:')});
			if (reason)
			{
				let res = await Grape.fetches.postJSON('/payment/reject', {'payment_id' : data, 'reason': reason });
				if (res.status == 'OK')
				{
					Grape.alerts.alert({type: 'success', message: window.tr('Successfully rejected payment.'), title: window.tr('Success')});
					this.page.updateData();
				}
				else if (res.status == 'ERROR') 
					Grape.alerts.apiError(res);
				else
					Grape.alerts.alert({type: 'error', message: window.tr('Something went wrong...'), title: window.tr('Error')});
			}
			else
				Grape.alerts.alert({type: 'danger', message: window.tr('No rejection reason provided'), title: window.tr('Invalid')});
		}
	};

	async edit_payment(row)
	{
		let data = ko.toJS(row);
		data.view = false;
		await Grape.dialog.open('GrapeAddPayment', { data: data });
		this.page.updateData();
	};

	download_requisition(res)
	{
		let payment_id = res;
		let url = '/download/payment/' + payment_id;
		window.open(url);
	};

	show_search_payment_help()
	{
		Grape.dialog.open('PaymentSearchHelp', {});
	}
};

class paymentSearchPage
{
	constructor(bindings)
	{
		this.bindings = bindings;
		this.viewModel = new paymentSearchViewModel(this);

		this.viewModel.reject_payment = this.viewModel.reject_payment.bind(this.viewModel);
		this.viewModel.view_payment = this.viewModel.view_payment.bind(this.viewModel);
		this.viewModel.edit_payment = this.viewModel.edit_payment.bind(this.viewModel);
		this.viewModel.delete_payment = this.viewModel.delete_payment.bind(this.viewModel);
		this.viewModel.download_requisition = this.viewModel.download_requisition.bind(this.viewModel);
	}

	async init()
	{
		document.title = window.tr('Payment Search');
		window.current_page_title(window.tr('Payment Search'));
		let buttonstring = [];
		let columns = [
			{field: 'payment_id', title: window.tr('ID'), sortable: true, align : 'left', width: '1%'},
			{field: 'requested_by', title: window.tr('Requested By'), sortable: true , align : 'left', width: '30%'},
			{field: 'payee', title: window.tr('Payee'), sortable: true , align : 'left', width: '30%'},
			{field: 'date_created', title: window.tr('Date Created'), sortable: true, align : 'left', width: '15%', searchable: false},
			{field: 'payment_date', title: window.tr('Payment Date'), sortable: true, align : 'left', width: '15%', searchable: false},
			{field: 'description', title: window.tr('Description'), sortable: true, align : 'left', width: '30%'},
			{field: 'total', title: window.tr('Total Amount'), width: '20%', sortable: true, align : 'left',
				formatter: function(value, row, index) {return 'R ' + row.total;}
			},
			{field: 'status', title: window.tr('Status'), sortable: true, align : 'left', width: '11%'},
			{field: 'Action',
				title: window.tr('Actions'),
				sortable: false,
				width: '300%',
				align : 'left',
				width: '1%',
				formatter: function(value, row, index) {
					let buttonstring = [];
					if (row.status == 'completed' || row.status == 'void' || row.status == 'rejected')
						buttonstring.push('<button class="ps-btn-primary ps-btn-sm edit_payment_button" disabled><span class="fa fa-pencil"></span></button>');
					else
						buttonstring.push('<button class="ps-btn-primary ps-btn-sm edit_payment_button"><span class="fa fa-pencil"></span></button>');

					buttonstring.push('<button class="ps-btn-default ps-btn-sm view_payment_button"><span class="fa fa-eye"></span></button>');
					buttonstring.push('<button class="ps-btn-danger ps-btn-sm delete_payment_button"><span class="fa fa-trash"></span></button>');
					if (row.status != 'rejected' && row.status != 'void')
						buttonstring.push('<button class="ps-btn-primary ps-btn-sm download_requisition_button" style="display: block; margin-top: 0.5em"><span class="fa-solid fa-download"></span>&nbsp;Download</button>');

					return `<div class="button-group">${buttonstring.join(' ')}</div>`;
				},
				events:{
					'click .reject_payment_button': (e, value, row, index) => {
						this.viewModel.reject_payment(row.payment_id);
					},
					'click .view_payment_button': (e, value, row, index) => {
						this.viewModel.view_payment(row);
					},
					'click .edit_payment_button': (e, value, row, index) => {
						this.viewModel.edit_payment(row);
					},
					'click .delete_payment_button': (e, value, row, index) => {
						this.viewModel.delete_payment(row);
					},
					'click .download_requisition_button': (e, value, row, index) => {
						this.viewModel.download_requisition(row.payment_id);
					}
				}
			}
		];

		this.tbl_employees = $('#tbl_payments').bootstrapTable({
			columns: columns,
			pagination: true,
			pageList: [10, 20, 50, 100],
			pageSize: 10,
			sidePagination: 'client',
			dataToolbar: '#toolbar',
			ordering: true,
			dataField: 'records',
			onPageChange: (number, size) => this.updateData(),
			onSort: (sortName, sortOrder) => this.updateData(),
			fixedColumns: true,
			fixedNumber: columns.length
		});
	};

	async updateData(filter = false)
	{
		let options = {
			table: 'v_subordinate_payments', 
			schema: 'grape_payment_approval',
			sortfield: 'payment_id',
			limit: 100000000,
			sortorder: 'DESC'
		};

		if (filter == true)
		{
			let filter_array = [];
			if (this.viewModel.date_start() && this.viewModel.date_end())
			{
				if (new Date(this.viewModel.date_end()) < new Date(this.viewModel.date_start()))
					Grape.alerts.alert({ type: 'warning', message: window.tr('End date cannot be before start date') });

				filter_array.push({ value: this.viewModel.date_end(), op: '<=', field: 'date_created' });
				filter_array.push({ value: this.viewModel.date_start(), op: '>=', field: 'date_created' });
			}

			if (this.viewModel.payment_date_start() && this.viewModel.payment_date_end())
			{
				if (new Date(this.viewModel.payment_date_end()) < new Date(this.viewModel.payment_date_start()))
					Grape.alerts.alert({ type: 'warning', message: window.tr('Payment end date cannot be before payment start date') });
				filter_array.push({ value: this.viewModel.payment_date_end(), operand: '<=', field: 'payment_date' });
				filter_array.push({ value: this.viewModel.payment_date_start(), operand: '>=', field: 'payment_date' });
			}

			options.filter = filter_array;
		}

		let table_options = $('#tbl_payments').bootstrapTable('getOptions');
		
		//LOGIC: Sort instantiate
		if (table_options.sortName && table_options.sortName != '')
		{
			options.sortfield = table_options.sortName;
			options.sortorder = table_options.sortOrder.toUpperCase();
		}

		if (this.viewModel.date_end() < this.viewModel.date_start())
			Grape.alerts.alert({ type: 'warning', message: window.tr('End date cannot be before start date') });
		
		if (this.viewModel.payment_date_end() < this.viewModel.payment_date_start())
			Grape.alerts.alert({ type: 'warning', message: window.tr('Payment end date cannot be before payment start date') });

		
		let res = await Grape.fetches.getJSON('/api/record', options);
		if (!res.records)
			res.records = [];

		$('#tbl_payments').bootstrapTable('load', res);
	};

	apply_filters()
	{
		let filter;
		this.updateData(filter = true);
	}
};

export default {
	route: '[/]payment_approval/payment_search',
	page_class: paymentSearchPage,
	template: template,
	template_filename: 'translation/payment_search' 
};
