import approval_structure_af from './pages/af/approval_structure.html';
import approval_structure_zu from './pages/zu/approval_structure.html';
import payment_af from './pages/af/payment.html';
import payment_zu from './pages/zu/payment.html';
import payment_list_af from './pages/af/payment_list.html';
import payment_list_zu from './pages/zu/payment_list.html';
import payment_report_af from './pages/af/payment_report.html';
import payment_report_zu from './pages/zu/payment_report.html';
import payment_search_af from './pages/af/payment_search.html';
import payment_search_zu from './pages/zu/payment_search.html';
import sample_page_af from './pages/af/sample_page.html';
import sample_page_zu from './pages/zu/sample_page.html';
import user_signatures_af from './pages/af/user_signatures.html';
import user_signatures_zu from './pages/zu/user_signatures.html';
import add_structure_af from './dialogs/af/add_structure.html';
import add_structure_zu from './dialogs/zu/add_structure.html';
import add_structure_line_af from './dialogs/af/add_structure_line.html';
import add_structure_line_zu from './dialogs/zu/add_structure_line.html';
import authorized_by_af from './dialogs/af/authorized_by.html';
import authorized_by_zu from './dialogs/zu/authorized_by.html';
import move_requisition_af from './dialogs/af/move_requisition.html';
import move_requisition_zu from './dialogs/zu/move_requisition.html';
import payment_af_dialog from './dialogs/af/payment.html';
import payment_zu_dialog from './dialogs/zu/payment.html';
import payment_approval_structure_help_af from './dialogs/af/payment_approval_structure_help.html';
import payment_approval_structure_help_zu from './dialogs/zu/payment_approval_structure_help.html';
import payment_help_af from './dialogs/af/payment_help.html';
import payment_help_zu from './dialogs/zu/payment_help.html';
import payment_report_help_af from './dialogs/af/payment_report_help.html';
import payment_report_help_zu from './dialogs/zu/payment_report_help.html';
import payment_search_help_af from './dialogs/af/payment_search_help.html';
import payment_search_help_zu from './dialogs/zu/payment_search_help.html';
import sample_dialog_af from './dialogs/af/sample_dialog.html';
import sample_dialog_zu from './dialogs/zu/sample_dialog.html';
import structure_line_help_af from './dialogs/af/structure_line_help.html';
import structure_line_help_zu from './dialogs/zu/structure_line_help.html';
import user_signature_help_af from './dialogs/af/user_signature_help.html';
import user_signature_help_zu from './dialogs/zu/user_signature_help.html';
import user_signature_upload_af from './dialogs/af/user_signature_upload.html';
import user_signature_upload_zu from './dialogs/zu/user_signature_upload.html';
 
 
export default {
'translation/approval_structure': {
'af' : approval_structure_af,
'zu' : approval_structure_zu
},
'translation/payment': {
'af' : payment_af,
'zu' : payment_zu
},
'translation/payment_list': {
'af' : payment_list_af,
'zu' : payment_list_zu
},
'translation/payment_report': {
'af' : payment_report_af,
'zu' : payment_report_zu
},
'translation/payment_search': {
'af' : payment_search_af,
'zu' : payment_search_zu
},
'translation/sample_page': {
'af' : sample_page_af,
'zu' : sample_page_zu
},
'translation/user_signatures': {
'af' : user_signatures_af,
'zu' : user_signatures_zu
},
'translation/dialogs/add_structure': {
'af' : add_structure_af,
'zu' : add_structure_zu
},
'translation/dialogs/add_structure_line': {
'af' : add_structure_line_af,
'zu' : add_structure_line_zu
},
'translation/dialogs/authorized_by': {
'af' : authorized_by_af,
'zu' : authorized_by_zu
},
'translation/dialogs/move_requisition': {
'af' : move_requisition_af,
'zu' : move_requisition_zu
},
'translation/dialogs/payment': {
'af' : payment_af_dialog,
'zu' : payment_zu_dialog
},
'translation/dialogs/payment_approval_structure_help': {
'af' : payment_approval_structure_help_af,
'zu' : payment_approval_structure_help_zu
},
'translation/dialogs/payment_help': {
'af' : payment_help_af,
'zu' : payment_help_zu
},
'translation/dialogs/payment_report_help': {
'af' : payment_report_help_af,
'zu' : payment_report_help_zu
},
'translation/dialogs/payment_search_help': {
'af' : payment_search_help_af,
'zu' : payment_search_help_zu
},
'translation/dialogs/sample_dialog': {
'af' : sample_dialog_af,
'zu' : sample_dialog_zu
},
'translation/dialogs/structure_line_help': {
'af' : structure_line_help_af,
'zu' : structure_line_help_zu
},
'translation/dialogs/user_signature_help': {
'af' : user_signature_help_af,
'zu' : user_signature_help_zu
},
'translation/dialogs/user_signature_upload': {
'af' : user_signature_upload_af,
'zu' : user_signature_upload_zu
}
}
