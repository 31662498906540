import template from './structure_line_help.html';

class StructureLineHelpViewModel
{
	constructor (dialog)
	{
		this.dialog = dialog;
	};

	close()
	{
		this.dialog.close();
	}
};

class StructureLineHelpPage
{
	constructor (bindings)
	{
		this.viewModel = new StructureLineHelpViewModel(this);
		this.bindings = bindings;
	};
};

// Register the dialog
export default {
	name: 'StructureLineHelp',
	dialog_class: StructureLineHelpPage,
	template: template,
	template_filename: 'translation/dialogs/structure_line_help',
	provider: 'ps'
};
